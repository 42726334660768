import React, { useState } from "react";
import Draggable from 'react-draggable';
import Container from "react-bootstrap/Container";
import Typist from 'react-typist-component';
import { Jumbotron } from "./migration";
import Window from "./Window";
import bcit from "../../assets/img/bcit.jpg";
import ubc from "../../assets/img/ubc.jpg";
import me from "../../assets/img/profile.jpg";
import home from "../../assets/img/home.jpg";

const MainBody = React.forwardRef(
  ({ gradient, title, message, icons, resume }, ref) => {
    const [allOpen, setAllOpen] = useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);
    const isMobile = width < 768;
  
    React.useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
    }, []);  

    return (
      <>
        <Draggable>
          <div>
            <Window
              title="me.jpg"
              width="250px"
              image={me}
              open={allOpen}
              x={window.innerWidth/4 - 145}
              y={window.innerHeight/1.25 - 200}
            />
          </div>
        </Draggable>
        <Draggable>
          <div>
            <Window
              title="home.jpg"
              image={home}
              open={allOpen}
              x={window.innerWidth/1.25 - 345}
              y={window.innerHeight/4 - 160}
            />
          </div>
        </Draggable>
        <Draggable>
          <div>
            <Window
              title="math.ubc"
              text="University of British Columbia<br/>Bachelor's Degree in Mathematics (2015-2020)"
              image={ubc}
              open={allOpen}
              x={window.innerWidth/1.25 - 245}
              y={window.innerHeight/1.25 - 200}
            />
          </div>
        </Draggable>
        <Draggable>
          <div>
            <Window
              title="computer_systems_technology.bcit"
              text="British Columbia Institute of Technology<br/>Diploma in Computer Systems Technology (2021-2023)"
              image={bcit}
              open={allOpen}
              x={window.innerWidth/4 - 245}
              y={window.innerHeight/4 - 160}
            />
          </div>
        </Draggable>
        <Draggable>
          <div>
            <Window
              title="readme.md"
              text="<p className='lead text-center'>Hello! I am a software developer student based in Vancouver, Canada. 
              I graduated from the University of British Columbia with a degree in mathematics 
              and from the British Columbia Institute of Technology with a diploma in Computer Systems Technology.
              <br/>
              <br/>
              I'm passionate about coding and enjoy working on a variety of projects in my free time.
              As a quick learner, I embrace new challenges and have even picked up programming languages on the fly while working on projects.
              I've gained valuable experience by contributing to several web applications, honing my skills and expanding my knowledge in the process.
              <br />
              <br />
              Thanks for stopping by and hope to hear from you soon!</p>"
              image={null}
              width="30rem"
              open={allOpen}
              x={window.innerWidth/2 - 245}
              y={window.innerHeight/2 - 160}
              resume={resume}
            />
          </div>
        </Draggable>
        <div onClick={() => setAllOpen(false)}>
          <Window
            title="Close All ✕"
            open={allOpen}
          />
        </div>

        <Jumbotron
          fluid
          id="home"
          style={{
            background: `linear-gradient(136deg,${gradient})`,
            backgroundSize: "1200% 1200%",
          }}
          className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
        >
          <div id="stars"></div>
          <Container className="text-center">
            <h1 ref={ref} className="display-1">
              {title}
            </h1>
            <Typist>
              <div className="lead typist">
                {message}
              </div>
            </Typist>
            <div className="p-5">
              {icons.map((icon, index) => (
                <a
                  key={`social-icon-${index}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={icon.url}
                  aria-label={`My ${icon.image.split("-")[1]}`}
                >
                  <i className={`fab ${icon.image}  fa-3x socialicons`} />
                </a>
              ))}
            </div>
            <button
              className="btn btn-outline-light btn-lg "
              aria-label="Learn more about me"
              onClick={!isMobile ? () => setAllOpen(true) : () => window.location.href = "#aboutme"}
            >
              More about me
            </button>
          </Container>
        </Jumbotron>
      </>
    );
  }
);

export default MainBody;
