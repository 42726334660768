import React from "react";

const GetInTouch = ({ message }) => {
  const email = "vince.lam120@gmail.com"
  return (
    <>
      <h2 className="display-4 pb-3 text-center">Get in Touch</h2>
      <p className="lead text-center pb-3">
        {message}, <a className="text-decoration-none" href={`mailto:${email}`}>{email}</a>.
      </p>
    </>
  );
};

export default GetInTouch;
