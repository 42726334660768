import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainBody from "./components/home/MainBody";
import AboutMe from "./components/home/AboutMe";
import Project from "./components/home/Project";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Skills from "./components/home/Skills";
import GetInTouch from "./components/home/GetInTouch.jsx";

const Home = React.forwardRef((props, ref) => {
  const resume = "https://docs.google.com/document/d/13MfuSiLBcAuecxEPnaL-dixgYjnEfIZ0YZ0aM4KvMgk/edit?usp=sharing";

  useEffect(() => {
    document.title="Vincent Lam";
  }, []);

  return (
    <>
      <MainBody
        gradient="#23283e, #49acc7, #ff9b11, #9b59b6, #46acc7, #23283e"
        title="Vincent Lam"
        message="New grad developer building the future, one line at a time."
        icons= {[
          {
            image: "fa-github",
            url: "https://github.com/Lamvince",
          },
          {
            image: "fa-linkedin",
            url: "https://www.linkedin.com/in/lamvin/",
          }
        ]}
        resume={resume}
        ref={ref}
      />
      <AboutMe
        link="./assets/images/profile.jpg"
        resume={resume}
      />
      <Project/>
      <Skills/>
    </>
  );
});

const App = () => {
  const titleRef = React.useRef();

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
      <Navbar ref={titleRef} />
      <Routes>
        <Route path="/" exact element={<Home ref={titleRef} />} />
      </Routes>
      <Footer>
        <GetInTouch
          message="I'm currently looking for full-time position working with software, front-end, back-end or full-stack developer opportunities! 
          If you know of any positions available, or if you just want to say hi, please feel free to email me at"
        />
      </Footer>
    </BrowserRouter>
  );
};

export default App;
