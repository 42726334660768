import React from "react";
import { Jumbotron } from "./migration";

const AboutMe = ({ link, resume }) => {
  const [profilePicUrl] = React.useState("");
  const [showPic] = React.useState(Boolean(link));
  const [width, setWidth] = React.useState(window.innerWidth);
  const imgSize = 375;
  const isMobile = width < 768;

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile && (
        <Jumbotron id="aboutme" className="m-0">
          <div className="container row">
            <div className="col-5 d-none d-lg-block align-self-center">
              {showPic && (
                <img
                  className="border border-secondary rounded-circle"
                  src={profilePicUrl}
                  alt="profilepicture"
                  width={imgSize}
                  height={imgSize}
                />
              )}
            </div>
            <div className={`col-lg-${showPic ? "7" : "12"}`}>
              <h2 className="display-4 mb-5 text-center">About Me</h2>
              <p className="lead text-center">Hello! I am a software developer student based in Vancouver, Canada. 
              I graduated from the University of British Columbia with a degree in mathematics 
              and from the British Columbia Institute of Technology with a diploma in Computer Systems Technology.
              <br/>
              <br/>
              I'm passionate about coding and enjoy working on a variety of projects in my free time.
              As a quick learner, I embrace new challenges and have even picked up programming languages on the fly while working on projects.
              I've gained valuable experience by contributing to several web applications, honing my skills and expanding my knowledge in the process.
              <br />
              <br />
              Thanks for stopping by and hope to hear from you soon!</p>
              <p className="lead text-center">
                <a
                  className="btn btn-outline-dark btn-lg"
                  href={resume}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  aria-label="Resume/CV"
                >
                  Resume
                </a>
              </p>
            </div>
          </div>
        </Jumbotron>
      )}
    </>
  );
};

export default AboutMe;