import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Skeleton from "react-loading-skeleton";

const ProjectCard = ({ image, hoverImage, name, type, description, technologies, link, parity }) => {
  const [hover, setHover] = useState(false);

  return (
    parity === "odd" ? (
    <Col md={12}>
      <Card className="card shadow-lg p-3 mb-5 bg-white rounded">
        <div className="row">
          <div className="col-md-3">
            <img src={hover && hoverImage ? hoverImage : image} className="img-fluid rounded" alt="description" 
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
          </div>
          <div className="col-md-9">
            <Card.Body>
              <Card.Title as="h5">{name || <Skeleton />} </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{type || <Skeleton />}</Card.Subtitle>
              <hr />
              <Card.Text dangerouslySetInnerHTML={{ __html: description }}></Card.Text>
              <Card.Text>{technologies}</Card.Text>
              <CardButtons link={link} />
            </Card.Body>
          </div>
        </div>
      </Card>
    </Col> ) : (
    <Col md={12}>
      <Card className="card shadow-lg p-3 mb-5 bg-white rounded">
        <div className="row">
          <div className="col-md-9">
            <Card.Body>
              <Card.Title as="h5">{name || <Skeleton />} </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{type || <Skeleton />}</Card.Subtitle>
              <hr />
              <Card.Text dangerouslySetInnerHTML={{ __html: description }}></Card.Text>
              <Card.Text>{technologies}</Card.Text>
              {link && <CardButtons link={link} />}
            </Card.Body>
          </div>
          <div className="col-md-3">
            <img src={hover && hoverImage ? hoverImage : image} className="img-fluid rounded" alt="description" 
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              />
          </div>
        </div>
      </Card>
    </Col>)
  );
};

const CardButtons = ({ link }) => {
  return (
    <div className="d-grid gap-2 d-md-block">
      <a href={link} target=" _blank" className="btn btn-outline-secondary mx-2">
        <i className="fas fa-link" />
      </a>
    </div>
  );
};


export default ProjectCard;