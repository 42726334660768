import Container from "react-bootstrap/Container";
import { Jumbotron } from "./migration";
import Row from "react-bootstrap/Row";
import ProjectCard from "./ProjectCard";
import gamequestbot from "../../assets/img/gamequestbot.png";
import gamequestbot0 from "../../assets/img/gamequestbot0.png";
import showoff from "../../assets/img/showoff.png";
import showoff0 from "../../assets/img/showoff0.png";
import pms from "../../assets/img/pms.png";
import extremis from "../../assets/img/extremis.png";
import extremis0 from "../../assets/img/extremis0.png";
import sportfolio from "../../assets/img/sportfolio.png";
import sportfolio0 from "../../assets/img/sportfolio0.png";
import music from "../../assets/img/music.png";
import music0 from "../../assets/img/music.gif";

const Project = () => {

  return (
    <Jumbotron fluid id="projects" className="bg-light m-0">
      <Container className="">
        <h2 className="display-4 pb-5 text-center">Projects</h2>
        <Row>
        <ProjectCard
            image={extremis}
            hoverImage={extremis0}
            name="Extremis"
            type="JavaScript, Nginx, Node.js, MySQL, Docker, Docker-Compose, Amazon EKS, Amazon S3, Amazon RDS"
            description="<p>Extreme weather warning web application that utilizes crowd-sourced information to warn users of extreme weather conditions. Extremis also provides a current weather forecast.</p>
            <li>Implemented a visually appealing and reactive front-end using HTML, CSS, and JavaScript</li>
            <li>Developed a full-stack web application using Node.js for the backend and responsive web design for the frontend served by Nginx</li>
            <li>Integrated LocationIQ API to retrieve location data</li>
            <li>Served as database manager, creating the database schema and implementing the database using MySQL, as well as writing SQL queries in the back-end to post and retrieve data</li>
              <li>Hosted database on Amazon RDS</li>
            <li>Utilized Docker and Docker Compose for development, testing, and deployment, integrating DevOps practices to streamline workflow and improve deployment efficiency</li>
              <li>Set up Amazon S3 bucket to store images</li>
              <li>Deployed the application with Kubernetes on Amazon EKS</li>
              <li>Linked AWS-hosted environment to custom domain with SSL/TLS for secure public access</li>"
            link="https://extremis-app.com/"
            parity="odd"
          />
          <ProjectCard
            image={gamequestbot}
            hoverImage={gamequestbot0}
            name="GameQuestBot"
            type="Dialogflow, Google Cloud, Node.js, MongoDB Atlas, rawg.io API"
            description="<p>Chatbot that accepts natural language input and recommends video games.</p>
              <li>Trained chatbot using Dialogflow to recognize video game data</li>
              <li>Integrated chatbot with MongoDB Atlas database</li>
              <li>Populated database from rawg.io API</li>
              <li>Implemented simple algorithm to match user inputs with video game metadata</li>
              <li>Programmed fulfillments to handle input and output logic</li>"
            link="https://lamvince.github.io/GameQuestBot/"
          />
          <ProjectCard
            image={showoff}
            hoverImage={showoff0}
            name="ShowOff"
            type="React.js, Node.js, Express.js, MongoDB, MongoDB Atlas"
            description="<p>Web application for student athletes to generate a shareable link to an 'athlete showcase' highlighing athletic ability.</p>
              <li>Utilized Express.js and Node.js to create a robust back-end, handling data storage and retrieval from a MongoDB database</li>
              <li>Implemented a user-friendly front-end using React.js for users to edit their profile</li>
              <li>Worked on front-end and back-end user input validation to ensure data integrity</li>
              <li>Collaborated with client to establish requirements and successfully delivered on objectives</li>"
            link="https://deployment-final--deluxe-smakager-694eb3.netlify.app"
            parity="odd"
          />
          <ProjectCard
            image={pms}
            name="Project Management System"
            type="JSF, Java/Jakarta EE, MySQL, OKD"
            description="<p>Enterprise software for managing projects, work packages, employees, and work hours.</p>
              <li>Served as test manager, creating and executing test plans and test cases for an enterprise systems webapp based on business requirements</li>
              <li>Ran unit, functional, integration and configuration tests at each sprint</li>
              <li>Compiled test results in spreadsheets and test report documentation</li>
              <li>Worked on the back-end, implementing the business logic using Jakarta EE</li>
              <li>Worked in a team of 12 to organize and plan the project</li>"
          />
          <ProjectCard
            image={sportfolio}
            hoverImage={sportfolio0}
            name="Sportfolio"
            type="Bootstrap, JavaScript, Firestore, Firebase"
            description="<p>Social media web application for athletes to connect with each other and talent scouts.</p>
              <li>Designed and implemented easy-to-use front-end using Bootstrap and CSS</li>
              <li>Built fully functional wireframe demo of the webapp using Figma as part of the planning process</li>
              <li>Utilized Firebase and Firestore to create a robust back-end, handling data storage and retrieval</li>"
            link="https://sportfolio-96c76.web.app/html/index.html"
            parity="odd"
          />
          <ProjectCard
            image={music}
            hoverImage={music0}
            name="MusicPlayer"
            type="C++, DirectShow Multimedia Framework"
            description="<p>Text based music player that can play, pause songs, as well as navigate and shuffle playlists.</p>
              <li>Hands on entry into C++ and handling media files, serving as a learning experience</li>
              <li>Utilized DirectShow Multimedia Framework to play audio files</li>
              <li>Implemented a doubly linked list to store songs</li>"
            link="https://github.com/Lamvince/MusicPlayer"
          />
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default Project;