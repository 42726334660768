import React, { useEffect, useState } from 'react';

const Window = ({ title, text, image, width, open, x, y, resume }) => {
    const [windowClass, setWindowClass] = useState('window window-close');

    useEffect(() => {
        setWindowClass(open ? 'window window-open rounded shadow-lg' : 'window window-close rounded');
    }, [open]);

    const closeWindow = () => {
        open = false;
        setWindowClass(open ? 'window window-open rounded shadow-lg' : 'window window-close rounded');
    };
    
    switch (title) {
        case "Close All ✕":
            return (
                <div className={windowClass} id="close" onClick={closeWindow}>{title}</div>
            );
        case "readme.md":
            return (
                <div className={windowClass} style={{"width": width, "left": x, "top": y}}>
                <div className="top">{title} <div className="close" onClick={closeWindow}>✕</div></div>
                <div className="text" dangerouslySetInnerHTML={{ __html: text }}></div>
                <p className="lead text-center">
                    <a
                    className="btn btn-outline-dark btn-lg"
                    href={resume}
                    target="_blank"
                    rel="noreferrer noopener"
                    role="button"
                    aria-label="Resume/CV"
                    >
                    Resume
                    </a>
                </p>
                </div>
            );
        default:
            return (
                <div className={windowClass} style={{"left": x, "top": y}} 
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}>
                    <div className="top">{title} <div className="close" onClick={closeWindow}>✕</div></div>
                    {text && <div className="text" dangerouslySetInnerHTML={{ __html: text }}></div> }
                    {image && <div className="img-div">
                        <img className="window-img" src={image} alt={title} style={{"width": width}} />
                    </div>
                    }
                </div>
            );
    }
};

export default Window;
