import React from "react";
import { Jumbotron } from "./migration";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const Skills = React.forwardRef((ref) => {
  const skillsTabRef = React.useRef(null);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const skills = [
    'React',
    'Bootstrap',
    'Node.js',
    'ASP.NET Core',
    'C#',
    'C/C++',
    'Java',
    'Jakarta EE',
    'JSF',
    'MySQL',
    'MongoDB',
    'Firebase',
    'Firestore',
    'Git',
    'Docker',
    'Docker-Compose',
    'Kubernetes',
    'AWS',
    'Agile Software Development',
    'System Testing',
    'Unit Testing',
  ]

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!isScrolled && currPos.y - 400 < 0) setIsScrolled(true);
    },
    [],
    skillsTabRef
  );
  return (
    <Jumbotron ref={skillsTabRef} fluid className="bg-white m-0" id="skills">
        <h2 ref={skillsTabRef} className="display-4 pb-5 text-center">
          Skills
        </h2>
        <ul className='skills__list'>
        {skills.map((skill) => (
          <li className='skills__list-item skills-btn skills-btn--plain rounded'>
            {skill}
          </li>
        ))}
      </ul>
    </Jumbotron>
  );
});

export default Skills;
